<template>
  <v-container class="fill-height wena my-0" fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
        xl="4"
      >
        <v-system-bar
          color="secondary"
          dark
          class="pb-7"
        >
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color v-on="on">
                <flag :iso="currentFlag" v-bind:squared="false" />
                <v-icon right color="primary">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(entry, index) in languagess"
                :key="`${index}010`"
                @click="changeLocale(entry)"
              >
                <v-list-item-title>
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  &nbsp;{{ entry.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-system-bar>
        <v-card class="pb-10">
          <v-row justify="center">
            <v-col
              cols="4"
              md="5"
            >
              <v-responsive class="pt-10">
                <v-img
                  width="300px"
                  src="/logo-kenmare-white.png"
                ></v-img>
              </v-responsive>
            </v-col>
          </v-row>
          <v-card-title class="subtitle-1 text-center justify-center px-12 pb-0">
            <p>{{ $t('We_detected_weakness_credentials') }}</p>
            <p >{{ $t('Create_strong_password') }}</p>
          </v-card-title>
          <v-card-text class="px-10">
            <v-row class="flex-column mt-2 px-3">
              <v-text-field
                v-model="password"
                :label="$t('Enter_your_new_password_field')"
                outlined
                type="password"
                dense
                required
                :rules="requiredRules"
              ></v-text-field>
              <v-text-field
                v-model="repeatPassword"
                :label="$t('confirm_password')"
                outlined
                type="password"
                dense
                required
                :rules="repeatPasswordRules"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <div class="text--disabled">*{{ $t('Your_password_must_be') }}</div>
              </v-col>
            </v-row>
            <div class="mt-5">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading || password === '' || (password !== repeatPassword)"
                color="primary"
                block
                @click.prevent="submit"
              >{{$t('Submit')}}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { API_URL } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import {
  UPDATE_USER_PASSWORD_MUTATION,
} from './../graphql/mutation.gql';
import { mapActions } from 'vuex';
import { formatError } from '@/utils';

export default {
  name: 'ResetPassword',
  data: () => ({
    currentFlag: "",
    isLoading: false,
    userAccount: null,
    password: "",
    repeatPassword: "",
  }),

  created() {
    this.currentFlag = localStorage.getItem("flag") ? localStorage.getItem("flag") : 'pt';
    this.fieldSelected = this.$route.query.typeEnter.type;
    this.userAccount = this.$route.query.any;
  },

  computed: {
    isEmail () {
      return this.$route.query.typeEnter.type === 'email'
        ? true : false;
    },
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    repeatPasswordRules() {
      return [(v) => v === this.password || `${this.$t('diff_password')}`];
    },
    requiredRules() {
      return [(v) => !!v || `${this.$t('password_required')}!`];
    },
  },

  methods: {
    ...mapActions({
      setUserState: 'auth/setUser'
    }),
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);
    },
    saveUserData(id, token) {
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
    },
    async submit() {
      if (this.password === this.repeatPassword) {
        this.isLoading = false;
        try {
          const { data } =  await this.$apollo.mutate({
            mutation: UPDATE_USER_PASSWORD_MUTATION,
            variables: {
              id: this.userAccount.id,
              password: this.password
            }
          });

          const id = data.updateUserPassword.user.id;
          const token = data.updateUserPassword.token;
          const loggedUser = data.updateUserPassword.user;

          this.saveUserData(id, token);
          this.setUserState(data.updateUserPassword.user);

          let loginUrl = localStorage.getItem("loginUrl")
            ? "/candidate" + localStorage.getItem("loginUrl")
            : localStorage.getItem("loginParams")
            ? "/candidate/"
            : "/candidate";

          switch (loggedUser.type) {
            case "candidate":
              this.$router.push(loginUrl);
              break;
            case "entity":
              this.$router.push(
                this.setRoute(loggedUser)
              );
              break;
            case "partner":
              this.$router.push("/partner");
              break;
              case "root":
              this.$router.push("/root");
              break;
          }

        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
}
</script>

<style scoped>
.wena {
  background: #2E4C33;
}
.title-card {
  white-space: pre-wrap;
}
</style>